import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/kaess_blog_header.png");
const section_1 = require("../../../assets/img/blogs/kaess_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/kaess_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/kaess_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/kaess_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/kaess_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/kaess_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/kaess_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/kaess_blog_img_8.png");
const section_9 = require("../../../assets/img/blogs/kaess_blog_img_9.png");
const section_10 = require("../../../assets/img/blogs/kaess_blog_img_10.png");
const section_11 = require("../../../assets/img/blogs/kaess_blog_img_11.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="How to use knowledge AI to create a self-service for employees"
        description="Leverage Knowledge AI to remove the friction of a rudimentary self-service and gain extended flexibility for knowledge discovery for your employees."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt kaess_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use{" "}
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      knowledge AI
                    </span>{" "}
                    to create a self-service for employees
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    How to use{" "}
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      knowledge AI <br />
                    </span>{" "}
                    to create a self-service
                    <br /> for employees
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why is self-service important today?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Can self-service solve employee challenges?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. How can you accelerate self-service capabilities?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What is Knowledge AI?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. How to use Knowledge AI for self-service for employees?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Workativ x Knowledge AI - Why are they the best combination
                  for employee self-service?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. How to use Workativ X Knowledge AI to create self-service
                  for employees?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section8"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section8"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section8")}
                >
                  8. What benefits you can leverage from Workativ X Knowledge AI
                  to enhance employee self-service?
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s assume it hasn’t gone past your notice that while you
                search on Google, you are provided with more organized and
                streamlined information for your query.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you need more clarity on some questions, you can look for the
                window to ask a follow-up with some suggestions for related
                questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The result behind this nicely constructed information is
                generated through deep AI technology 一{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is rather an experimental approach for any solution provider
                to usher in the true potential of new technology and drive new
                business innovation to use it to solve business problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say you are using a sheet application in the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lately, you have discovered an addition of a Generative AI
                solution, which makes your work even more flexible and reduces
                manual iterations for a task that needs you to create a
                financial report.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Reflecting upon its ability to generate text content or other
                complex NLP tasks can bring forth two critical use cases in the
                present business scenario for self-service via chatbot
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using Generative AI, it is easier to automate the creation of
                documentation for various business functions while you can also
                use it to automate search functions and knowledge discovery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Two of these critical use cases are effective in transforming
                how employees today utilize{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  {" "}
                  self-service platforms.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The generic form of answers can deliver knowledge but no
                specificity to help align with specific business scenarios.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other side of the constraint on chatbot solutions,
                FAQ-based self-services fall short of user expectations when
                providing domain-specific answers to consumers or employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI alone is not enough to help you bring
                domain-specific knowledge. We will explain this later in this
                article.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is a powerful tool for business leaders to bring in
                custom solutions in self-service functionality and give their
                employees the ability to ask domain-specific questions and get
                help instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn how Knowledge AI gives self-service the efficient
                ability to harness domain-specific knowledge and give answers to
                unique questions for employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is self-service important today?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="self-service for support efficiency "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As per an Oracle eBook, 40% of consumers have a strong
                willingness to use{" "}
                <a href="https://www.oracle.com/a/ocom/docs/spark-series-making-sense-of-guided-self-service.pdf">
                  self-service functionality,
                </a>{" "}
                and 69% of consumers want to solve their problems through a
                self-service portal without asking for help from a human agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees across businesses seek a similar approach to solving
                their productivity challenges through self-service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say employees are peak at productivity level on a software
                testing side before a project launch. The access login page,
                however, is admin-controlled. The admin is out of the office.
                Now, you can visualize the ruckus here.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this scenario, a team would love to get login information
                autonomously through a self-service instead of running after
                that admin who is on leave.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The business objective is to enable them to get information as
                flexibly as they can handle their logins for image-sharing
                platforms or online shopping apps.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service functionality conveniently helps employees find
                information steadily, solve problems, and be productive.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can self-service solve employee challenges?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                As is with the changing business needs, businesses must ensure
                they have a proactive self-service for employees to provide them
                with a solution they can handle themselves and get a
                frictionless resolution of problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses have realized the need for automation and AI in their
                process workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Password reset or account unlocks are common challenges a
                chatbot with a self-service functionality can easily handle.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="self-service for common user queries at the workplace "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                But, imagine an employee wants to switch the context of a query
                in an ongoing conversation and seeks a solution for another
                problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service built with FAQ-based workflow automation or
                predefined questionnaires can barely understand context
                switching and provide the necessary answers.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can you accelerate self-service capabilities?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the changing demands of your employees on the autonomous
                problem-solving side, you can use natural language processing
                and machine learning to provide answers to simple to complex
                questions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The newest addition to the block to augment chatbot self-service
                capability is Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, a Generative AI chatbot can only be as good as long you
                need answers to generic forms of questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say you want to know about an existing format of a document
                related to a release testing of a client app to craft a
                stakeholder report.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A Generative AI chatbot with self-service capabilities can help
                an employee fetch information related to release testing. But to
                your dismay, it is as generic as other release testing and can
                relate to just any other form of applications or software
                suites.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is not feasible for your employees to craft a custom report
                specific to your business persona.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The lack of domain-specific knowledge can lead a self-service
                chatbot to perform at a lower expectation level of your
                employees, resulting in productivity losses and employee
                frustration.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To achieve domain-specificity, fine-tuning or LLM training can
                be an effective option, but one must pay attention to the high
                costs of computing resources and the time it needs to go live.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Business leaders can consider{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                – a feasible and cost-effective solution to train and deploy
                self-service chatbots for custom answers to employees' queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is Knowledge AI?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI integrates the power of large language models (LLM)
                and Generative AI to gain the ability to perform NLP tasks for
                employee questions by leveraging inputs from knowledge base
                articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Knowledge AI provides the easiest way to train its LLM through a
                method that allows you to upload knowledge base articles in any
                format. This ability makes it easy to implement NLP to
                understand the context of users’ queries and retrieve answers
                that match intent and context – no matter if spelling is wrong
                or sentence syntax is incorrect.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" intelligent agent handoff with Knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If you look closely, a business may use a different brand and
                model of a printer. In this case, a solution provided through
                Knowledge AI can be limited and results in a fallback scenario.
                As a result, it promptly guides the user to a live agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, if there is no satisfactory match, Knowledge AI does
                not disappoint, and it triggers a fallback scenario. As a
                result, it promptly guides the user to a live agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simply put, Knowledge AI delivers user satisfaction by fetching
                domain-specific answers. On the other hand, it allows users to
                receive personalized responses when a call is transferred to an
                agent.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use Knowledge AI for self-service for employees?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The best thing a business can get from Knowledge AI is that this
                emergent technology offers multiple ways to benefit employees
                and help them resolve problems efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI use cases are highly suitable for people who
                streamline repetitive and mundane business processes and
                automate employee search performance and knowledge discovery.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  First-contact resolutions are easy for service desks.
                </li>
              </ul>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="FCR with Knowledge AI "
              />
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                First-contact resolutions matter as they relate to how a
                company’s service desk efficiently handles a user query in a
                first call. If a ticket consequently travels to several tiers,
                it goes on to add agent handling costs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                A service desk backed by Knowledge AI can provide enough
                resources for agents to find a user’s problem. It is easier for
                the agent to collect existing knowledge resources such as
                available comments and conversation history links.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                An agent can craft personalized messages containing references
                to users’ problems suggested by Knowledge AI and respond to them
                steadily and efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Users can easily understand tips and help them out of a problem.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Agents are twice as efficient in serving requesters.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Say a scenario demands a novice agent to handle a call.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With Knowledge AI implemented in a service desk platform, agents
                can look steady and easily handle a user’s query by retrieving
                information suggested by Knowledge AI that fetches information
                from the article bases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI provides an efficient way to modify, edit, and
                train LLM by providing updated information.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Whenever a unique case comes, Knowledge AI can draw the latest
                information from the LLM and provide suggestions to an agent for
                more business specificity.
              </p>
              <img src={section_5} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18  pl-5">
                At the same time, agents can make less effort to craft a
                personalized message for specific problems without asking for an
                expert’s help, giving senior management more time to focus on
                complicated tasks and drive results.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                This results in employees receiving enhanced service desk
                experience, further inspiring them to use self-service widely.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Knowledge AI keeps employee service available for 24x7.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Self-service can be a tireless and round-the-clock service
                delivery channel for employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                More than often, employees could look for information in a
                traditional way. Emails and voice calls are a go-to medium for
                them while they have a self-service functionality available.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Limited Q&A FAQ or self-service bots can offer little
                information and not enough flexibility they may need to find
                information and resolve their problems.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI drives self-service efficiency, making it easy for
                them to find answers to common employee questions easily.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With the enhanced self-service capability for employees to find
                answers, Knowledge AI improves knowledge discovery performance
                and inspires adoption at scale.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                As a result, employees can easily leverage self-service anytime
                they need information and get work done.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Knowledge AI helps improve the Employee Net Promoter Score.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                The flexibility and convenience of information delivery are the
                best things Knowledge AI can do to keep employees hooked to
                self-serve for every common problem that eventually becomes a
                hurdle in completing a task.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                The scale of information delivery, including knowledge
                discovery, provides an elevated user experience and
                encouragement to succeed at finding information autonomously,
                thus increasing the rate of engagement towards work for
                employees.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                As a result, Knowledge AI drives bespoke user engagement and net
                promoter scores.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Self-service for better engagement with humans and AI
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Sometimes, a user may struggle to fix a common problem
                autonomously.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5 mb-0">
                Say a password reset is enabled through a Knowledge AI
                self-service. But, the user finds it inoperative.
              </p>
              <img src={section_6} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                In such a case, employees immediately seek a human touch for a
                critical problem.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI goes on to suggest agent handoffs with full details
                of conversation history and problem statements.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                An agent needs no further explanation and steadily checks on the
                app that causes access issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Instant human connection provides a more pleasant experience to
                employees and helps them to get back to work.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                How to use Knowledge AI to create employee self-service?
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To build a powerful self-service that can be best at detecting
                intent and entity to comprehend a user utterance better and
                provide answers, Knowledge AI is effective.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI is a cloud-based option for users to leverage to
                  upload knowledge-base articles and be able to feed large
                  language models with business-specific data.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most service providers have simple iterations for Knowledge AI
                  to implement. It requires no coding and critical technical
                  abilities for deployment.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Applying just a few clicks of a mouse within an existing&nbsp;
                  <a href="https://workativ.com/conversational-ai-platform">
                    conversational AI chatbot,
                  </a>{" "}
                  users can implement the Knowledge AI feature.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Once Knowledge AI is enabled, a self-service chatbot
                  automatically fetches information from it and surfaces
                  information in the chat window.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                {" "}
                Workativ x Knowledge AI - Why are they the best combination for
                employee self-service?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ
                </a>{" "}
                has a new addition of Generative AI in the form of Knowledge AI
                to allow users to have a ChatGPT-like information delivery
                feature for businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead of delivering a generic form of information like those
                most search engines provide, which is otherwise not useful in
                the case of business-specific tasks, Workativ advocates for more
                contextual and domain-specific search and knowledge discovery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                provides the advantage of a large language model and Generative
                AI to accelerate content generation and information search,
                Workativ ensures users get the flexibility of domain-specific
                information in a more straightforward way through its
                conversational AI platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Workativ and Knowledge AI combine to deliver precise,
                summarized, and meaningful answers to help employees perform
                their tasks.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="intent and context detection by Knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                One best example of Workativ and its Knowledge AI feature is
                that its conversational AI platform understands the user’s
                intent and provides the best match for an NLP query.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of proposing KB links, it provides condensed and
                meaningful answers to accelerate the speed of employee
                workflows.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use Workativ X Knowledge AI to create self-service for
                employees?
              </h2>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Knowledge AI platform to build an LLM architecture 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ is a cloud-based and no-code platform. It also features
                an intuitive interface for users to implement Knowledge AI
                features in a very simple way and in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Within the Workativ conversational AI platform, users can find
                the Knowledge AI implementation button. So, it is a familiar
                environment for existing users to implement Knowledge AI and
                leverage the benefit of fast information delivery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, unless you create your knowledge bases for the
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Knowledge AI platform,
                </a>{" "}
                Workativ can barely surface answers from its Knowledge AI
                platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how to build your knowledge bases for Knowledge AI and
                leverage the power of LLM and Generative AI.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Upload your Knowledge AI articles.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is the first step to making your Knowledge AI more robust
                and effective for answering domain-specific answers. By
                uploading KB articles to the Knowledge AI platform, you can
                build an enriching information repository system for employees.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Workativ KB - If you wish to provide answers common to every
                  industry across HR or IT support functions, prefer knowledge
                  base articles from Workativ KB, also known as internal KB.
                </li>
                <li className="font-section-normal-text-testimonials">
                  External KB - Connect all your third-party apps that contain
                  business-specific data. They can comprise anything in any
                  format, such as HRIS system, SharePoint, Google Drive,
                  Dropbox, and so on, to allow your employees to find
                  workplace-related information at scale and find a resolution
                  steadily.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Website KB - Your website is a huge resource center for
                  employees to find information and complete work. It has a
                  community forum, ebooks, case studies, product features,
                  product or service details, etc. By giving Knowledge AI access
                  to your website KB, employees can find critical information
                  anytime and anywhere as they need to work efficiently without
                  putting too much effort.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Integrate Knowledge AI search functionality.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The next significant step is to integrate Knowledge AI search
                within the workflows. As you create your workflow automation for
                a specific use case, apply Knowledge AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say you want to create a workflow for a Welcome message, you can
                prepare your questions and implement Knowledge AI from the
                drop-down menu.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Knowledge AI integration
                "
              />
              <h3 className="font-section-sub-header-small-home">
                Keep your KB articles up to date.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As your service desk or IT help desk experiences the unique
                needs of an employee, existing KB article information may need a
                revision based on the solution provided. This helps employees
                solve their problems autonomously and reduces the escalation of
                a ticket to the higher-tier agent services.
              </p>

              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Generative AI custom answers 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides the ability to update knowledge resources by
                conveniently applying features, such as “full train,” “train
                new” or “modified content,” and “train new, modified, or deleted
                content.”
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you keep your knowledge AI resources updated, your
                employees get standardized and consistent information without
                the probability of losing the context of information and being
                able to complete a task.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Try Knowledge AI before going live.
              </h3>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt=" test and trial for Knowledge AI conversation   
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Before you put your employees at risk by guiding them to apply
                Knowledge AI in the wrong way unintentionally, it is wise to
                test and trial how your Knowledge AI workflows work at their
                best.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Use the Try Me button at the bottom of the Knowledge AI
                platform. Ask a question and verify if Knowledge AI can answer
                appropriately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If it returns appropriate answers, there is no work for you.
                But, if there is some error, check for improvements.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Optimize self-service use
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is bland to expect that your people are quite eager to adapt
                to a sudden workplace change.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unless you invite them and help them understand the
                effectiveness of a Knowledge AI-based self-service, they will
                keep using emails or waiting for agents to help them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Prepare an engaging email series to nudge them to use Knowledge
                AI for enhanced productivity and experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ does not require your employees to learn a new
                technology repeatedly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can easily adapt and use Knowledge AI as it flexibly
                embeds within a familiar channel like MS Teams and Slack to give
                users an{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                  omnichannel experience.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Gather user feedback
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI tends to become stale if it does not provide
                meaningful information for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As business scenarios keep changing and unique solutions emerge,
                employees need contextual information to solve problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no better way to create a feedback workflow and ask
                your employees to provide their thoughts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The feedback they provide would be a great asset to improve
                Knowledge AI workflows and keep your employees engaged.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What benefits can you leverage from Workativ X Knowledge AI to
                enhance employee self-service?
              </h2>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-3">
                <li className="font-section-normal-text-testimonials">
                  Conversational AI and Knowledge AI features of the Workativ
                  platform can translate into enhanced user experience for
                  employees as they combine to make search functionality faster
                  and more meaningful.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Conversational AI offers better comprehension for NLP queries
                through detection and extraction of intent and entity, while
                Knowledge AI helps provide summarized answers for faster
                resolution of problems.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  Workativ allows agents to leverage Knowledge AI to generate
                  personalized responses unique to each employee query. Agents
                  can unleash less effort to detect the problem's root and
                  provide resolutions intuitively.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI and conversational AI help reduce response
                  generation time. With the ability to give Knowledge AI access
                  to the latest information periodically, Workativ ensures
                  employees get up-to-date information instantly and reduces the
                  wait time.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Compared to FAQ-based self-service, which seems rudimentary
                  and repeats the same questions for lack of advanced NLP
                  capabilities, Workativ provides more flexibility in retrieving
                  meaningful answers in less time. As a result, employees can
                  remove friction from the self-service channel and look more
                  confident as they use Knowledge AI.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI from Workativ is a unique LLM-powered solution for
                business leaders to apply the benefits of Generative AI and have
                the opportunity to remain competitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you want a cost-effective GenAI solution for your mundane
                business processes, Workativ{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                can help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To leverage the benefits of Knowledge AI,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today&nbsp;
                </a>
                with Workativ sales experts.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why is self-service important today?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Can self-service solve employee challenges?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. How can you accelerate self-service capabilities?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What is Knowledge AI?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. How to use Knowledge AI for self-service for employees?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Workativ x Knowledge AI - Why are they the best
                    combination for employee self-service?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. How to use Workativ X Knowledge AI to create self-service
                    for employees?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section8"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section8"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section8")}
                  >
                    8. What benefits you can leverage from Workativ X Knowledge
                    AI to enhance employee self-service?
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s assume it hasn’t gone past your notice that while you
                search on Google, you are provided with more organized and
                streamlined information for your query.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                If you need more clarity on some questions, you can look for the
                window to ask a follow-up with some suggestions for related
                questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The result behind this nicely constructed information is
                generated through deep AI technology 一{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-dummies">
                  Generative AI.
                </a>
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                It is rather an experimental approach for any solution provider
                to usher in the true potential of new technology and drive new
                business innovation to use it to solve business problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say you are using a sheet application in the workplace.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Lately, you have discovered an addition of a Generative AI
                solution, which makes your work even more flexible and reduces
                manual iterations for a task that needs you to create a
                financial report.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Reflecting upon its ability to generate text content or other
                complex NLP tasks can bring forth two critical use cases in the
                present business scenario for self-service via chatbot
                solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Using Generative AI, it is easier to automate the creation of
                documentation for various business functions while you can also
                use it to automate search functions and knowledge discovery.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Two of these critical use cases are effective in transforming
                how employees today utilize{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  {" "}
                  self-service platforms.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The generic form of answers can deliver knowledge but no
                specificity to help align with specific business scenarios.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other side of the constraint on chatbot solutions,
                FAQ-based self-services fall short of user expectations when
                providing domain-specific answers to consumers or employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI alone is not enough to help you bring
                domain-specific knowledge. We will explain this later in this
                article.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI is a powerful tool for business leaders to bring in
                custom solutions in self-service functionality and give their
                employees the ability to ask domain-specific questions and get
                help instantly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn how Knowledge AI gives self-service the efficient
                ability to harness domain-specific knowledge and give answers to
                unique questions for employees.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why is self-service important today?
              </h2>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="self-service for support efficiency "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                As per an Oracle eBook, 40% of consumers have a strong
                willingness to use{" "}
                <a href="https://www.oracle.com/a/ocom/docs/spark-series-making-sense-of-guided-self-service.pdf">
                  self-service functionality,
                </a>{" "}
                and 69% of consumers want to solve their problems through a
                self-service portal without asking for help from a human agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees across businesses seek a similar approach to solving
                their productivity challenges through self-service.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say employees are peak at productivity level on a software
                testing side before a project launch. The access login page,
                however, is admin-controlled. The admin is out of the office.
                Now, you can visualize the ruckus here.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In this scenario, a team would love to get login information
                autonomously through a self-service instead of running after
                that admin who is on leave.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The business objective is to enable them to get information as
                flexibly as they can handle their logins for image-sharing
                platforms or online shopping apps.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service functionality conveniently helps employees find
                information steadily, solve problems, and be productive.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Can self-service solve employee challenges?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                As is with the changing business needs, businesses must ensure
                they have a proactive self-service for employees to provide them
                with a solution they can handle themselves and get a
                frictionless resolution of problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Businesses have realized the need for automation and AI in their
                process workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Password reset or account unlocks are common challenges a
                chatbot with a self-service functionality can easily handle.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="self-service for common user queries at the workplace "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                But, imagine an employee wants to switch the context of a query
                in an ongoing conversation and seeks a solution for another
                problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A self-service built with FAQ-based workflow automation or
                predefined questionnaires can barely understand context
                switching and provide the necessary answers.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can you accelerate self-service capabilities?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the changing demands of your employees on the autonomous
                problem-solving side, you can use natural language processing
                and machine learning to provide answers to simple to complex
                questions.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                The newest addition to the block to augment chatbot self-service
                capability is Generative AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, a Generative AI chatbot can only be as good as long you
                need answers to generic forms of questions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Say you want to know about an existing format of a document
                related to a release testing of a client app to craft a
                stakeholder report.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                A Generative AI chatbot with self-service capabilities can help
                an employee fetch information related to release testing. But to
                your dismay, it is as generic as other release testing and can
                relate to just any other form of applications or software
                suites.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is not feasible for your employees to craft a custom report
                specific to your business persona.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The lack of domain-specific knowledge can lead a self-service
                chatbot to perform at a lower expectation level of your
                employees, resulting in productivity losses and employee
                frustration.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To achieve domain-specificity, fine-tuning or LLM training can
                be an effective option, but one must pay attention to the high
                costs of computing resources and the time it needs to go live.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Business leaders can consider{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                – a feasible and cost-effective solution to train and deploy
                self-service chatbots for custom answers to employees' queries.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What is Knowledge AI?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI integrates the power of large language models (LLM)
                and Generative AI to gain the ability to perform NLP tasks for
                employee questions by leveraging inputs from knowledge base
                articles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Knowledge AI provides the easiest way to train its LLM through a
                method that allows you to upload knowledge base articles in any
                format. This ability makes it easy to implement NLP to
                understand the context of users’ queries and retrieve answers
                that match intent and context – no matter if spelling is wrong
                or sentence syntax is incorrect.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt=" intelligent agent handoff with Knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If you look closely, a business may use a different brand and
                model of a printer. In this case, a solution provided through
                Knowledge AI can be limited and results in a fallback scenario.
                As a result, it promptly guides the user to a live agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, if there is no satisfactory match, Knowledge AI does
                not disappoint, and it triggers a fallback scenario. As a
                result, it promptly guides the user to a live agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Simply put, Knowledge AI delivers user satisfaction by fetching
                domain-specific answers. On the other hand, it allows users to
                receive personalized responses when a call is transferred to an
                agent.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use Knowledge AI for self-service for employees?
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                The best thing a business can get from Knowledge AI is that this
                emergent technology offers multiple ways to benefit employees
                and help them resolve problems efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Knowledge AI use cases are highly suitable for people who
                streamline repetitive and mundane business processes and
                automate employee search performance and knowledge discovery.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  First-contact resolutions are easy for service desks.
                </li>
              </ul>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="FCR with Knowledge AI "
              />
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                First-contact resolutions matter as they relate to how a
                company’s service desk efficiently handles a user query in a
                first call. If a ticket consequently travels to several tiers,
                it goes on to add agent handling costs.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                A service desk backed by Knowledge AI can provide enough
                resources for agents to find a user’s problem. It is easier for
                the agent to collect existing knowledge resources such as
                available comments and conversation history links.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                An agent can craft personalized messages containing references
                to users’ problems suggested by Knowledge AI and respond to them
                steadily and efficiently.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Users can easily understand tips and help them out of a problem.
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Agents are twice as efficient in serving requesters.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Say a scenario demands a novice agent to handle a call.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With Knowledge AI implemented in a service desk platform, agents
                can look steady and easily handle a user’s query by retrieving
                information suggested by Knowledge AI that fetches information
                from the article bases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI provides an efficient way to modify, edit, and
                train LLM by providing updated information.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Whenever a unique case comes, Knowledge AI can draw the latest
                information from the LLM and provide suggestions to an agent for
                more business specificity.
              </p>
              <img src={section_5} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18  pl-5">
                At the same time, agents can make less effort to craft a
                personalized message for specific problems without asking for an
                expert’s help, giving senior management more time to focus on
                complicated tasks and drive results.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                This results in employees receiving enhanced service desk
                experience, further inspiring them to use self-service widely.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Knowledge AI keeps employee service available for 24x7.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Self-service can be a tireless and round-the-clock service
                delivery channel for employees.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                More than often, employees could look for information in a
                traditional way. Emails and voice calls are a go-to medium for
                them while they have a self-service functionality available.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Limited Q&A FAQ or self-service bots can offer little
                information and not enough flexibility they may need to find
                information and resolve their problems.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI drives self-service efficiency, making it easy for
                them to find answers to common employee questions easily.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                With the enhanced self-service capability for employees to find
                answers, Knowledge AI improves knowledge discovery performance
                and inspires adoption at scale.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                As a result, employees can easily leverage self-service anytime
                they need information and get work done.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Knowledge AI helps improve the Employee Net Promoter Score.
                </li>
              </ul>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                The flexibility and convenience of information delivery are the
                best things Knowledge AI can do to keep employees hooked to
                self-serve for every common problem that eventually becomes a
                hurdle in completing a task.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                The scale of information delivery, including knowledge
                discovery, provides an elevated user experience and
                encouragement to succeed at finding information autonomously,
                thus increasing the rate of engagement towards work for
                employees.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                As a result, Knowledge AI drives bespoke user engagement and net
                promoter scores.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-sub-header-small-home color-black pl-4 mb-2">
                <li className="font-section-sub-header-small-home color-black">
                  {" "}
                  Self-service for better engagement with humans and AI
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Sometimes, a user may struggle to fix a common problem
                autonomously.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5 mb-0">
                Say a password reset is enabled through a Knowledge AI
                self-service. But, the user finds it inoperative.
              </p>
              <img src={section_6} className="blog_image_top_bt" />
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                In such a case, employees immediately seek a human touch for a
                critical problem.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI goes on to suggest agent handoffs with full details
                of conversation history and problem statements.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                An agent needs no further explanation and steadily checks on the
                app that causes access issues.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 pl-5">
                Instant human connection provides a more pleasant experience to
                employees and helps them to get back to work.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                How to use Knowledge AI to create employee self-service?
              </h3>
              <p class="font-section-normal-text-testimonials line-height-18">
                To build a powerful self-service that can be best at detecting
                intent and entity to comprehend a user utterance better and
                provide answers, Knowledge AI is effective.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd">
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI is a cloud-based option for users to leverage to
                  upload knowledge-base articles and be able to feed large
                  language models with business-specific data.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Most service providers have simple iterations for Knowledge AI
                  to implement. It requires no coding and critical technical
                  abilities for deployment.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Applying just a few clicks of a mouse within an existing&nbsp;
                  <a href="https://workativ.com/conversational-ai-platform">
                    conversational AI chatbot,
                  </a>{" "}
                  users can implement the Knowledge AI feature.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Once Knowledge AI is enabled, a self-service chatbot
                  automatically fetches information from it and surfaces
                  information in the chat window.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                {" "}
                Workativ x Knowledge AI - Why are they the best combination for
                employee self-service?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ
                </a>{" "}
                has a new addition of Generative AI in the form of Knowledge AI
                to allow users to have a ChatGPT-like information delivery
                feature for businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Instead of delivering a generic form of information like those
                most search engines provide, which is otherwise not useful in
                the case of business-specific tasks, Workativ advocates for more
                contextual and domain-specific search and knowledge discovery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                At a time when{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                provides the advantage of a large language model and Generative
                AI to accelerate content generation and information search,
                Workativ ensures users get the flexibility of domain-specific
                information in a more straightforward way through its
                conversational AI platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Workativ and Knowledge AI combine to deliver precise,
                summarized, and meaningful answers to help employees perform
                their tasks.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="intent and context detection by Knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                One best example of Workativ and its Knowledge AI feature is
                that its conversational AI platform understands the user’s
                intent and provides the best match for an NLP query.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of proposing KB links, it provides condensed and
                meaningful answers to accelerate the speed of employee
                workflows.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                How to use Workativ X Knowledge AI to create self-service for
                employees?
              </h2>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Knowledge AI platform to build an LLM architecture 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ is a cloud-based and no-code platform. It also features
                an intuitive interface for users to implement Knowledge AI
                features in a very simple way and in no time.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Within the Workativ conversational AI platform, users can find
                the Knowledge AI implementation button. So, it is a familiar
                environment for existing users to implement Knowledge AI and
                leverage the benefit of fast information delivery.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                However, unless you create your knowledge bases for the
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Knowledge AI platform,
                </a>{" "}
                Workativ can barely surface answers from its Knowledge AI
                platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here’s how to build your knowledge bases for Knowledge AI and
                leverage the power of LLM and Generative AI.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Upload your Knowledge AI articles.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is the first step to making your Knowledge AI more robust
                and effective for answering domain-specific answers. By
                uploading KB articles to the Knowledge AI platform, you can
                build an enriching information repository system for employees.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Workativ KB - If you wish to provide answers common to every
                  industry across HR or IT support functions, prefer knowledge
                  base articles from Workativ KB, also known as internal KB.
                </li>
                <li className="font-section-normal-text-testimonials">
                  External KB - Connect all your third-party apps that contain
                  business-specific data. They can comprise anything in any
                  format, such as HRIS system, SharePoint, Google Drive,
                  Dropbox, and so on, to allow your employees to find
                  workplace-related information at scale and find a resolution
                  steadily.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Website KB - Your website is a huge resource center for
                  employees to find information and complete work. It has a
                  community forum, ebooks, case studies, product features,
                  product or service details, etc. By giving Knowledge AI access
                  to your website KB, employees can find critical information
                  anytime and anywhere as they need to work efficiently without
                  putting too much effort.
                </li>
              </ul>
              <h3 className="font-section-sub-header-small-home">
                Integrate Knowledge AI search functionality.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                The next significant step is to integrate Knowledge AI search
                within the workflows. As you create your workflow automation for
                a specific use case, apply Knowledge AI.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say you want to create a workflow for a Welcome message, you can
                prepare your questions and implement Knowledge AI from the
                drop-down menu.
              </p>
              <img
                src={section_9}
                className="blog_image_top_bt"
                alt="Knowledge AI integration
                "
              />
              <h3 className="font-section-sub-header-small-home">
                Keep your KB articles up to date.
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                As your service desk or IT help desk experiences the unique
                needs of an employee, existing KB article information may need a
                revision based on the solution provided. This helps employees
                solve their problems autonomously and reduces the escalation of
                a ticket to the higher-tier agent services.
              </p>

              <img
                src={section_10}
                className="blog_image_top_bt"
                alt="Generative AI custom answers 
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ provides the ability to update knowledge resources by
                conveniently applying features, such as “full train,” “train
                new” or “modified content,” and “train new, modified, or deleted
                content.”
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                When you keep your knowledge AI resources updated, your
                employees get standardized and consistent information without
                the probability of losing the context of information and being
                able to complete a task.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Try Knowledge AI before going live.
              </h3>
              <img
                src={section_11}
                className="blog_image_top_bt"
                alt=" test and trial for Knowledge AI conversation   
                "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Before you put your employees at risk by guiding them to apply
                Knowledge AI in the wrong way unintentionally, it is wise to
                test and trial how your Knowledge AI workflows work at their
                best.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Use the Try Me button at the bottom of the Knowledge AI
                platform. Ask a question and verify if Knowledge AI can answer
                appropriately.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If it returns appropriate answers, there is no work for you.
                But, if there is some error, check for improvements.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Optimize self-service use
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is bland to expect that your people are quite eager to adapt
                to a sudden workplace change.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Unless you invite them and help them understand the
                effectiveness of a Knowledge AI-based self-service, they will
                keep using emails or waiting for agents to help them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Prepare an engaging email series to nudge them to use Knowledge
                AI for enhanced productivity and experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ does not require your employees to learn a new
                technology repeatedly.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Employees can easily adapt and use Knowledge AI as it flexibly
                embeds within a familiar channel like MS Teams and Slack to give
                users an{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                  omnichannel experience.
                </a>
              </p>
              <h3 className="font-section-sub-header-small-home">
                Gather user feedback
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI tends to become stale if it does not provide
                meaningful information for employees.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As business scenarios keep changing and unique solutions emerge,
                employees need contextual information to solve problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no better way to create a feedback workflow and ask
                your employees to provide their thoughts.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The feedback they provide would be a great asset to improve
                Knowledge AI workflows and keep your employees engaged.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section8">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                What benefits can you leverage from Workativ X Knowledge AI to
                enhance employee self-service?
              </h2>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4 mb-3">
                <li className="font-section-normal-text-testimonials">
                  Conversational AI and Knowledge AI features of the Workativ
                  platform can translate into enhanced user experience for
                  employees as they combine to make search functionality faster
                  and more meaningful.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-4">
                Conversational AI offers better comprehension for NLP queries
                through detection and extraction of intent and entity, while
                Knowledge AI helps provide summarized answers for faster
                resolution of problems.
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials pl-4">
                <li className="font-section-normal-text-testimonials">
                  Workativ allows agents to leverage Knowledge AI to generate
                  personalized responses unique to each employee query. Agents
                  can unleash less effort to detect the problem's root and
                  provide resolutions intuitively.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Knowledge AI and conversational AI help reduce response
                  generation time. With the ability to give Knowledge AI access
                  to the latest information periodically, Workativ ensures
                  employees get up-to-date information instantly and reduces the
                  wait time.
                </li>
                <li className="font-section-normal-text-testimonials">
                  Compared to FAQ-based self-service, which seems rudimentary
                  and repeats the same questions for lack of advanced NLP
                  capabilities, Workativ provides more flexibility in retrieving
                  meaningful answers in less time. As a result, employees can
                  remove friction from the self-service channel and look more
                  confident as they use Knowledge AI.
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Knowledge AI from Workativ is a unique LLM-powered solution for
                business leaders to apply the benefits of Generative AI and have
                the opportunity to remain competitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you want a cost-effective GenAI solution for your mundane
                business processes, Workativ{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                can help.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To leverage the benefits of Knowledge AI,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  schedule a demo today&nbsp;
                </a>
                with Workativ sales experts.
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-service-desk"
              className="font-section-normal-text-testimonials"
            >
              Reactive to Proactive: Leveraging Generative AI for Smarter
              Employee Service Desks
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise"
              className="font-section-normal-text-testimonials"
            >
              How can Enterprises Leverage Generative AI?
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/generative-ai-save-itsm-cost"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Top 5 Ways CXOs Can Save ITSM Cost Using Generative AI
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
